import axios from "axios";
import store from "../store";
import route from "../router";
// import Message from 'iview/src/components/message';
import { Message } from 'element-ui';
import Vue from 'vue' // 引入vue
// let _Message = Message;
import Cookies from "js-cookie";
import constant from '@/utils/constant.js'
import util from '@/utils/tools.js'
import {
    apiBase,
    experCodeApi,
    experDockerApi
} from '../router/init';
console.log("apiBase:",apiBase)
// 环境的切换
// console.log(process.env.VUE_APP_BASE_API,'process.env.VUE_APP_BASE_API')
const service = axios.create({
    baseURL: apiBase,
    timeout: 1800000,
});
const experCodeService = axios.create({
    baseURL: experCodeApi,
    timeout: 1800000,
});
const experDockerService = axios.create({
    baseURL: experDockerApi,
    timeout: 1800000,
});
// request拦截器
service.interceptors.request.use(
    (config) => {
        if (Cookies.get(constant.tokenName)) {
            config.headers["access-token"] = Cookies.get(constant.tokenName); //store.state.userInfo.token; // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        config.headers["REQUEST_API_URL"] = apiBase;
        return config;
    },
    (error) => {
        // Do something with request error
        Promise.reject(error);
    }
);

//  响应拦截
service.interceptors.response.use(
    (res) => {
        if (res.data.error) {
            // 判断token是否过期，过期跳转首页重新登录
            if(res.data.error == 'auth_fail'){
                let domain = util.getDomain();
                console.log(domain,'++++++')
                Cookies.remove(constant.tokenName,{domain})
                //删除store
                // store.commit('logout', this);
                // store.commit('clearOpenedSubmenu');
                store.commit('clearCurModule');

                route.push('/login')
            }else{
                Message({
                    message: res.data.message,
                    type:'error',
                    duration : 10000,
                });
            }
            // Message.destroy();

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

            return Promise.reject(res.data)

        }else{
            return res.data;
        }

    },
    (err) => {
        Message({message : err.response.data.message, duration : 10000,type:'error'});
        return err;
    }
);

experCodeService.interceptors.request.use(
    (config) => {
        if (Cookies.get(constant.tokenName)) {
            config.headers["access-token"] = Cookies.get(constant.tokenName); //store.state.userInfo.token; // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        config.headers["REQUEST_API_URL"] = experCodeApi;
        return config;
    },
    (error) => {
        // Do something with request error
        Promise.reject(error);
    }
);

//  响应拦截
experCodeService.interceptors.response.use(
    (res) => {
        if (res.data.error) {
            // 判断token是否过期，过期跳转首页重新登录
            if(res.data.error == 'auth_fail'){
                route.push('/login')

            }else{
                Message({
                    message: res.data.message,
                    type:'error',
                    duration : 10000,
                });
            }
            // Message.destroy();

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

            return Promise.reject(res.data)

        }else{
            return res.data;
        }

    },
    (err) => {
        Message({message : err.response.data.message, duration : 10000,type:'error'});
        return err;
    }
);


experDockerService.interceptors.request.use(
    (config) => {
        if (Cookies.get(constant.tokenName)) {
            config.headers["access-token"] = Cookies.get(constant.tokenName); //store.state.userInfo.token; // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        config.headers["REQUEST_API_URL"] = experDockerApi;
        return config;
    },
    (error) => {
        // Do something with request error
        Promise.reject(error);
    }
);

//  响应拦截
experDockerService.interceptors.response.use(
    (res) => {
        console.log(res,'================')
        if (res.data.error) {
            // 判断token是否过期，过期跳转首页重新登录
            if(res.data.error == 'auth_fail'){
                let domain = util.getDomain();
                console.log(domain,'++++++')
                Cookies.remove(constant.tokenName,{domain})
                //删除store
                // store.commit('logout', this);
                // store.commit('clearOpenedSubmenu');
                store.commit('clearCurModule');
                route.push('/login');

            }else{
                Message({
                    message: res.data.message,
                    type:'error',
                    duration : 10000,
                });
            }
            // Message.destroy();

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

            return Promise.reject(res.data)

        }else{
            return res.data;
        }

    },
    (err) => {
        Message({message : err.response.data.message, duration : 10000,type:'error'});
        return err;
    }
);

export function request(options) {
    return new Promise((resolve, reject) => {
        service(options)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function downLoadRequest(options) {
    return new Promise((resolve, reject) => {
        service(options)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function experCodeRequest(options) {
    return new Promise((resolve, reject) => {
        experCodeService(options)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function experDockerRequest(options) {
    return new Promise((resolve, reject) => {
        experDockerService(options)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
